import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { graphql, Link } from 'gatsby';
import {
  Container, Row, Col, Button
} from 'react-bootstrap';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon
} from 'react-share';

import DomPurify from '@core/helpers/dom-purify';
import ParagraphComponent from '@core/helpers/paragraph-helpers';
import { IndexesProvider, useIndexes } from '@core/providers/index';
import { slug } from '@core/helpers/strings';

import Hero from '@shared/hero/hero';
import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';
import CardBlog from '@shared/card/blog';

import { Graphql } from '@models/graphql';

import allNodeBlogQuery from './default-query';

import './styles.scss';

const { blog: crumbs } = require('@config/breadcrumbs');

const bannerBlogType = 'paragraph__paragraph_banner_blog';
const bannerCtaType = 'paragraph__paragraph_banner_cta';

type BlogProps = {
  language: string;
  data: {
    blog: Graphql;
  };
};

export default function BlogPage({
  data,
  language
}: BlogProps): React.ReactElement {
  const { t } = useTranslation();
  const { blog } = data;
  const { relationships } = blog || {};

  const paragraphs = relationships?.paragraphs?.map(
    (node) => ParagraphComponent({ ...node, path: blog.path }, language)
  );

  const sidebar = relationships?.sidebar?.map((node) => {
    const type = node.type === bannerCtaType ? bannerCtaType : bannerBlogType;

    return ParagraphComponent({ ...node, type, path: blog.path }, language);
  });

  const blogNodes = allNodeBlogQuery();
  const nodes: Graphql[] = blogNodes?.blogs?.edges ?? [];
  const posts = nodes
    .map((post) => post.node)
    .filter((post) => post?.id !== blog.id);

  const relatedPosts = posts.filter((post) => {
    const tags = post?.relationships?.tags?.map((tag) => tag.name);

    return tags?.filter((
      _tag
    ) => relationships?.tags?.map((tag) => tag.name).includes(_tag)).length;
  }) as Graphql[];

  return (
    <>
      <IndexesProvider>
        <div className="blog--page">
          {relationships?.hero && (
            <div className="hero-container mb-5">
              {blog?.premium && (
                <div className="container-md">
                  <div className="premium position-absolute px-2 pt-1 mt-5 ml-1 d-inline-block max-content">
                    <h6 className="text-white">{t('Premium')}</h6>
                  </div>
                </div>
              )}
              <Hero
                node={{
                  ...relationships.hero,
                  subtitle: `${blog.date ? blog.date : null}`
                }}
              />
            </div>
          )}
          <Breadcrumb
            crumbs={crumbs.concat({ label: blog.title, link: '#' })}
          />
          <Container className="position-relative">
            <Row className="mt-4 mb-2">
              <Col
                className="d-flex align-items-center justify-content-between"
                md={9}
              >
                <div className="d-flex justify-content-between align-items-baseline">
                  {relationships?.tags && relationships?.tags?.length > 0 && (
                    <div className="tags-container">
                      <span className="tags-label text-bold">
                        {`${t('Tags')}: `}
                      </span>
                      {relationships.tags.map((tag) => tag.name).join(', ')}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="position-relative" md={3}>
                <Row>
                  {relationships?.mostreadlink
                    && relationships.mostreadlink.length > 0 && (
                    <Sidebar
                      title={t('The most read')}
                      node={relationships.mostreadlink}
                    />
                  )}
                  <SharedLinks />
                  <TableContent title={t('Table of contents')} />
                  {relationships?.interestlink && (
                    <Sidebar
                      title={t('Quick Access')}
                      node={relationships.interestlink}
                    />
                  )}
                </Row>
              </Col>
              <Col md={9}>
                {blog?.wysiwyg?.processed && (
                  <div className="text-black wysiwyg mb-3">
                    <DomPurify
                      includeIndex
                      showIndex={false}
                      text={blog.wysiwyg.processed}
                    />
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col className="position-relative" />
            </Row>
          </Container>
          {paragraphs && paragraphs.length > 0 && (
            <div className="page-components-container">{paragraphs}</div>
          )}
        </div>
      </IndexesProvider>
      {relationships?.interestlink && (
        <RelatedBlogs relatedBlogs={relatedPosts} />
      )}
    </>
  );
}

type RelatedBlogsProps = {
  relatedBlogs: Graphql[];
};

function RelatedBlogs({ relatedBlogs }: RelatedBlogsProps) {
  if (relatedBlogs.length <= 0) {
    return <></>;
  }

  const { t } = useTranslation();

  return (
    <>
      {relatedBlogs?.length > 0 && (
        <Container className="interested-links mt-4 w-100">
          <Row>
            <Col>
              <h4 className="mt-4 mb-4">{t('Related Blogs')}</h4>
            </Col>
          </Row>
          <Row>
            {relatedBlogs.slice(0, 6).map((interest) => (
              <Col
                key={interest.id}
                md={6}
                lg={4}
                className="component card-blog mb-5"
              >
                <CardBlog node={interest} />
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
}

function SharedLinks() {
  const [shareUrl, setShareUrl] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setShareUrl(typeof window !== 'undefined' ? window.location.href : '');
  }, []);

  return (
    <div className="col-12">
      <div className="row">
        <span className="text-bold small text-marine text-xl">
          {`${t('Share')}`}
        </span>
      </div>
      <div className="row">
        <span className="mr-3">
          <FacebookShareButton url={shareUrl} className="rounded">
            <FacebookIcon bgStyle={{ fill: '#022A4E' }} size={30} />
          </FacebookShareButton>
        </span>
        <span className="mr-3">
          <TwitterShareButton url={shareUrl} className="rounded">
            <TwitterIcon bgStyle={{ fill: '#022A4E' }} size={30} />
          </TwitterShareButton>
        </span>
        <span className="mr-3">
          <LinkedinShareButton url={shareUrl} className="rounded">
            <LinkedinIcon bgStyle={{ fill: '#022A4E' }} size={30} />
          </LinkedinShareButton>
        </span>
      </div>
    </div>
  );
}

type SidebarProps = {
  title: string;
  node: Graphql[];
};

type TableContentProps = {
  title: string;
};

function Sidebar({ title, node }: SidebarProps) {
  return (
    <div className="quick-access mx-3 mx-md-0">
      <h4 className="quick-access-title text-nice-blue my-4">{title}</h4>
      {node.slice(0, 4).map((relatedPost) => (
        <div key={relatedPost.id} className="related-blog mb-4">
          <Link to={relatedPost.link?.uri || ''}>
            <h6 className="mb-1 text-dark-indigo text-bold">
              {relatedPost.title}
            </h6>
          </Link>
          {relatedPost.relationships?.tags
            && relatedPost.relationships.tags.length > 0 && (
            <div className="tags-container mt-4">
              {relatedPost.relationships.tags.map((tag) => (
                <Button key={tag.id} variant="light">
                  {tag.name}
                </Button>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

function TableContent({ title }: TableContentProps) {
  const { indexes } = useIndexes();
  const [activeIndex, setActiveIndex] = useState(0);

  const onIndexLinkClickHandler = (
    event: React.MouseEvent,
    element: Graphql,
    index: number
  ) => {
    event.preventDefault();
    setActiveIndex(index);

    scrollToElement(element.id || '');
  };

  const scrollToElement = (elementId: string) => {
    document?.getElementById(elementId)?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  };
  return (
    <>
      {indexes && (
        <div className="table-content mx-3 mx-md-0">
          <h4 className="table-content-title text-marine text-xl text-bold my-4">
            {title}
          </h4>
          {indexes?.map((item, index) => (
            <div key={slug(item.innerText || '')}>
              <a
                href={`#${slug(item.innerText || '')}`}
                key={slug(item.innerText || '')}
                className={`cursor-pointer mt-2 text-base ${
                  index === activeIndex ? 'text-tangerine' : 'text-marine'
                }`}
                onClick={(event) => onIndexLinkClickHandler(event, item, index)}
              >
                {item.innerText}
              </a>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    blog: nodeBlog(id: { eq: $id }) {
      id
      title
      featured: field_featured
      premium: field_premium
      wysiwyg: body {
        processed
      }
      path {
        alias
        langcode
      }
      date: field_date(locale: "en", formatString: "MMMM DD, YYYY")
      relationships {
        hero: field_hero {
          ...HeroParagraph
        }
        tags: field_tags {
          name
        }
        author: field_author {
          name: title
        }
        paragraphs: field_content_main {
          type: __typename
          ...CtaParagraph
          ...HeroParagraph
          ...HeroSliderParagraph
          ...BannerParagraph
          ...BannerTalentParagraph
          ...BannerAdvParagraph
          ...QuoteSliderParagraph
          ...QuoteParagraph
          ...IntroTextParagraph
          ...IntroBlockParagraph
          ...TimelineParagraph
          ...CardsParagraph
          ...BoxesParagraph
          ...GridParagraph
          ...ClientsParagraph
          ...TechnologiesParagraph
          ...TabsParagraph
          ...TableParagraph
          ...WysiwygParagraph
          ...ViewParagraph
          ...FeaturedContentParagraph
          ...BannerFormParagraph
          ...BannerTalentApplyParagraph
          ...EmergencyModalParagraph
          ...BannerVideoParagraph
          ...WebFormParagraph
          ...CollageParagraph
          ...FabParagraph
          ...ImageSliderParagraph
          ...OfficesParagraph
        }
        sidebar: field_sidebar {
          type: __typename
          ...BannerParagraph
          ...BannerCTAParagraph
        }
        interestlink: field_interest_links {
          type: __typename
          ... on node__blog {
            id
            type: __typename
            title
            langcode
            wysiwyg: body {
              summary
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              image: field_thumbnail {
                name

                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
          ... on node__whitepaper {
            id
            type: __typename
            title
            langcode
            wysiwyg: body {
              summary
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              image: field_thumbnail {
                name

                attributes: field_media_image {
                  alt
                  title
                }
              }
            }
          }
          ... on node__success_story {
            id
            type: __typename
            title
            langcode
            wysiwyg: body {
              summary
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              image: field_image {
                filename

              }
            }
            image: field_image {
              alt
              title
            }
          }
        }
        mostreadlink: field_most_read_links {
          type: __typename
          ... on node__blog {
            id
            type: __typename
            title
            langcode
            created
            wysiwyg: body {
              summary
            }
            link: path {
              uri: alias
              langcode
            }
            relationships {
              image: field_thumbnail {
                name

                attributes: field_media_image {
                  alt
                  title
                }
              }
              tags: field_tags {
                name
                id
              }
            }
          }
        }
      }
    }
  }
`;
